a {
  color: #fe7306;
  text-decoration: none;
}
a:hover {
  color: #522b1c;
}

#nav-container {
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
}

/* for navbar */
.navbar-dark .nav-item .nav-link {
  color: #fff;
}

.navbar-dark .nav-item .nav-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
  border-radius: 0.25rem;
  color: #fff;
}

.fa-li {
  position: relative;
  left: 0;
}
#logo {
  height: 120px;
}
.logo-container {
  width: 9%;
}
.rightnavsection {
  width: 92%;
}
.search-navbar-together {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  align-items: center;
}
.search-container {
  width: 50%;
}

/* CSS for mobile screens (up to 767px width) */
@media only screen and (max-width: 767px) {
  #nav-container {
    flex-flow: column;
  }
  .search-container {
    width: 100%;
  }
}

/* ===================================== */

/* Search Navbar components */

.search {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  text-align: center;
}

.search__input {
  font-family: inherit;
  font-size: inherit;
  background-color: #f4f2f2;
  border: none;
  color: rgb(0, 0, 0);
  padding: 0.8rem 1rem;
  border-radius: 10px;
  width: calc(100% - 80px);
  transition: all ease-in-out 0.5s;
  margin-right: -2rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
@media only screen and (max-width: 767px) {
  .search-container {
    width: 100%;
    margin-bottom: 20px;
  }
  #faqDesktop {
    display: none;
  }
}

.search__input:hover,
.search__input:focus {
  /* box-shadow: 0 0 1em #00000013; */
}

.search__input:focus {
  outline: none;
  background-color: #f0eeee;
  color: rgb(24, 12, 12);
}

.search__input::-webkit-input-placeholder {
  font-weight: 100;
  color: #ccc;
}

.search__input:focus {
  background-color: #f0eeee;
}
.search__input::placeholder {
  color: #424242; /* Change to your desired color */
}

.search__button {
  border: none;
  background-color: #f4f2f2;
  border: none;
  color: rgb(0, 0, 0);
  padding: 0.8rem 1rem;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 80px;
  transition: all ease-in-out 0.5s;
}

.search__button:hover {
  cursor: pointer;
  background-color: #fe7306;
  color: white;
}

.search__icon {
  height: 1.3em;
  width: 1.3em;
  fill: rgb(8, 8, 8);
}
.search__icon:hover {
  fill: #f4f2f2;
}

/* for Extra nav link */

.navbar-scroll .nav-link,
.navbar-scroll .fa-bars {
  font-weight: 600;
  color: #ffffff;
}

.navbar-scrolled .nav-link,
.navbar-scrolled .fa-bars {
  color: #7f4722;
}

.navbar-scrolled {
  background-color: #ffede7;
}

.nav-link:hover {
  color: #522b1c;
}

/* Carousel */
.carousel-image {
  max-width: 100%;
  max-height: 100%;
}
.slick-list {
  /* max-height: 350px; */
}
.container-fluid {
  --bs-gutter-x: 2.5rem;
}

/* ================Categories ========================== */

.card-container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 20px 0;
  justify-content: space-between;
  width: 100%;
}
.cardContainer {
  width: 175px;
  height: 175px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.card {
  position: relative;
  width: 170px;
  height: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px;
  margin: 10px;
  border-radius: 10px;
  backdrop-filter: blur(30px);
  background-color: #c0ac9f4b;
  border: 1px solid rgba(255, 255, 255, 0.089);
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);
  transition: all 0.5s;
  /* cursor: pointer; */
}
.card:hover {
  box-shadow: -2px 1px 4px 2px rgba(0, 0, 0, 0.2);
}

.genre {
  font-weight: 700;
  font-size: 1.2em;
  letter-spacing: 1.2px;
  color: rgb(0, 0, 0);
  text-align: center;
}
.card img {
  width: 50%;
}

.cardContainer::before {
  width: 60px;
  height: 60px;
  content: "";
  position: absolute;
  background-color: #fe720675;
  z-index: -1;
  border-radius: 50%;
  left: 30px;
  top: 30px;
  transition: all 0.7s;
}

.cardContainer:hover::before {
  transform: translate(-10px, 20px);
}

.h2 {
  font-weight: 700;
}

/* ============Author=============== */
.author-card-container {
  width: 100%;
  overflow-x: auto;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}

.author-card-wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.author-card-wrapper a {
  color: black;
}

.author-card {
  width: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  flex: 0 0 auto;
  scroll-snap-align: start;
}
.author-card span {
  font-size: 1.2em;
}

.author-photo-container {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid;
}

.author-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.author-name {
  margin-top: 10px;
  text-align: center;
}
.author-name:hover {
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);
  color: red;
}

/* ====================Best selling Books Section ==================== */
.books-card {
  width: 17%;
  height: 320px;
  border-radius: 20px;
  background: #f5f5f5;
  position: relative;
  padding: 1.8rem;
  border: 2px solid #c3c6ce;
  -webkit-transition: 0.5s ease-out;
  transition: 0.5s ease-out;
  overflow: visible;
  margin: 0 5px;
}
.books-card:nth-child(n) {
  align-self: flex-start;
}

.books-card-details {
  color: #fe7306;
  height: 100%;
  place-content: center;
  font-family: "Courier New", Courier, monospace;
}

.books-card-button {
  text-decoration: none;
  text-align: center;
  -webkit-transform: translate(-50%, 125%);
  -ms-transform: translate(-50%, 125%);
  transform: translate(-50%, 125%);
  width: 70%;
  border-radius: 1rem;
  border: none;
  background-color: #fe7306;
  color: #fff;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  position: absolute;
  left: 50%;
  bottom: 0;
  opacity: 0;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
  cursor: pointer;
  font-family: "Courier New", Courier, monospace;
}

.books-text-body {
  color: rgb(0, 0, 0);
}

/*Text*/
.books-text-title {
  font-size: 1.5em;
  font-weight: bold;
}

/*Hover*/
.books-card:hover {
  border-color: #fe7306;
  -webkit-box-shadow: 10px 5px 18px 0 rgba(255, 255, 255, 0.877);
  box-shadow: 10px 5px 18px 0 rgba(255, 255, 255, 0.877);
}

.books-card:hover .books-card-button {
  -webkit-transform: translate(-50%, 50%);
  -ms-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
  opacity: 1;
}
.booksimage {
  width: 100%;
  height: 75%;
  object-fit: contain;
}
.bestbooks-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.bookslist {
  display: flex;
  justify-content: space-between;
}

/* =========================Genre =========================== */
.genre-card {
  width: 170px;
  height: 170px;
  background: #f0f0f0;
  position: relative;
  display: flex;
  flex-flow: column;
  place-content: center;
  place-items: center;
  overflow: hidden;
  border-radius: 20px;
}
.genre-card:hover {
  background-color: #fe7306;
}

.genre-card h2,
.genre-card img {
  z-index: 1;
  color: rgb(0, 0, 0);
  font-size: 1.2em;
  text-align: center;
}
.genreimg {
  width: 50%;
}

.genre-card:hover::before {
  content: "";
  position: absolute;
  width: 100px;
  background-image: linear-gradient(
    180deg,
    rgb(0, 183, 255),
    rgb(255, 48, 255)
  );
  height: 130%;
  animation: rotBGimg 3s linear infinite;
  transition: all 0.2s linear;
}

@keyframes rotBGimg {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.genre-card::after {
  content: "";
  position: absolute;
  background: #f0f0f0;
  inset: 5px;
  border-radius: 15px;
}

/* ===============App store ======================= */
.appcontainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 300px;
}
.appcontainer > div {
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}
.app-text-link {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
.app-photos img {
  height: 85%;
}
.app-link {
  display: flex;
}
.gradient-bg {
  background: linear-gradient(-135deg, #96d010, #fcf3f3);
  border-radius: 15px;
}
.playstore-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #000;
  border-radius: 9999px;
  background-color: rgba(0, 0, 0, 1);
  padding: 0.625rem 1.5rem;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  outline: 0;
  transition: all 0.2s ease;
  text-decoration: none;
}

.playstore-button:hover {
  background-color: transparent;
  color: rgba(0, 0, 0, 1);
}

.playstore-icon {
  height: 1.5rem;
  width: 1.5rem;
}

.playstore-texts {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 1;
}

.playstore-text-1 {
  margin-bottom: 0.25rem;
  font-size: 0.75rem;
  line-height: 1rem;
}

.playstore-text-2 {
  font-weight: 600;
}

.istore-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #000;
  border-radius: 9999px;
  background-color: rgba(0, 0, 0, 1);
  padding: 0.625rem 1.5rem;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  outline: 0;
  transition: all 0.2s ease;
  text-decoration: none;
}

.istore-button:hover {
  background-color: transparent;
  color: rgba(0, 0, 0, 1);
}

.istore-icon {
  height: 1.5rem;
  width: 1.5rem;
}

.istore-texts {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 1;
}

.istore-text-1 {
  margin-bottom: 0.25rem;
  font-size: 0.75rem;
  line-height: 1rem;
}

.istore-text-2 {
  font-weight: 600;
}

/* ======================Contact form ============================= */
.contact-form {
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #ffd3b0;
  border-radius: 15px;
}

.contact-form h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.contact-form p {
  margin-bottom: 20px;
}

.form-columns {
  display: flex;
  justify-content: space-between;
}

.form-column {
  flex: 0 0 48%;
}

.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.form-group {
  flex: 0 0 48%;
  margin-right: 4%; /* Add some spacing between input fields */
}

.form-group:last-child {
  margin-right: 0; /* Remove spacing for the last input field */
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 10px;
  border: none;
  border-bottom: 1px solid #ccc;
}
/* .form-group textarea{
  
} */

.form-group textarea {
  height: 100px;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.form-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.form-buttons button:first-child {
  background-color: #fff;
  color: #333;
}

.form-buttons button:last-child {
  background-color: #fe7306;
  color: #fff;
  border-radius: 20px;
}

.form-buttons button:last-child:hover {
  background-color: #522b1c;
  color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2), 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* ==================Footer ====================== */
.footer-menu {
  list-style: none;
  display: flex;
  justify-content: space-evenly;
}
.footer-menu li {
  margin: 0 10px;
}

/* =================Social media icon ======================= */

.socials-container {
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  padding: 20px 40px;
  /* background-color: #333333; */
}
.social {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid rgb(0, 0, 0);
}
.twitter:hover,
.x:hover {
  background: linear-gradient(45deg, #66757f, #00090c, #383838, #dbedff);
}
.social svg:hover {
  fill: white;
}
.facebook:hover {
  background: linear-gradient(45deg, #134ac0, #316ff6, #78a3ff);
}
.linkedin:hover {
  background: linear-gradient(45deg, #000000, #000000, #000000);
}
.linkedin-icon:hover {
  fill: rgb(255, 255, 255);
}
.instagram:hover {
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}
.social svg {
  fill: rgb(112, 112, 112);
  height: 20px;
}

/* =============================== About section =================================== */

/* General Styles */
body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1.6;
  color: #333;
}

/* Hero Section Styles */
.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 60px auto;
}

.hero-content {
  flex: 1;
  padding-right: 40px;
}

.hero-content h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.hero-image {
  flex: 1;
  text-align: right;
}

.hero-image img {
  max-width: 350px;
  height: auto;
}

/* History Section Styles */
.history {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 60px auto;
  background-color: #f8f9fa;
  padding: 40px;
  border-radius: 10px;
}

.history-content {
  flex: 1;
  padding-right: 40px;
}

.history-content h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.history-image {
  flex: 1;
  text-align: right;
}

.history-image img {
  max-width: 320px;
  height: auto;
}

.vision {
  position: relative;
  padding: 5px 15px;
  border-radius: 15px;
  /* Add other styles for your section */
}

.vision::before {
  content: '';
  position: absolute;
  border-radius: 15px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url('../../../public/imagespu/universe-2742113_1280.jpg');
  background-size: cover;
  opacity: 0.1; /* Adjust the opacity to control the lightening effect */
  z-index: -1;
}

.vision-content {
  position: relative; /* Ensure content stays above the pseudo-element */
  z-index: 1; /* Ensure content stays above the pseudo-element */
  /* Add other styles for your content */
}

/* ==================FAQ ============================ */
.faq-container {
  margin: 0 auto;
  padding: 20px;
}

.faq-heading {
  text-align: center;
}

.faq-list {
  margin-top: 20px;
}

.faq-item {
  border-bottom: 1px solid #ddd;
  padding: 10px;
}

.faq-question {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-answer {
  display: none;
  padding: 10px;
  transition: all 0.3s ease-out;
}

.faq-question.open {
  color: #fe7306; /* Change color when item is open */
}

.faq-answer.open {
  display: block;
  transition: all 0.3s ease-in;
}
